import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import FingoDialog from './FingoDialog';
import UpdateCompanyInformation from '../forms/UpdateCompanyInformation';
import { GET_LEGAL_MASTER_ENTITY } from '../../graphql';

const UpdateCompanyInformationDialog = ({ open, setOpen, masterEntityId, isNaturalPersonRut }) => {
  const { data, refetch } = useQuery(GET_LEGAL_MASTER_ENTITY, {
    variables: { masterEntityId },
    skip: !open,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editedValues, setEditedValues] = useState({
    name: '',
    nationality: '',
    fantasyNameCompliance: '',
    phone: '',
    email: '',
    profession: '',
    countryName: '',
    contractualPurpose: '',
    originFunds: '',
  });

  const handleCloseDialog = () => {
    setIsEditing(false);
    setEditedValues({
      name: '',
      nationality: '',
      fantasyNameCompliance: '',
      phone: '',
      email: '',
      profession: '',
      countryName: '',
      contractualPurpose: '',
      originFunds: '',
    });
    setOpen(false);
  };

  return (
    <FingoDialog
      title="Información cliente"
      open={open}
      handleClose={handleCloseDialog}
      maxWidth="xl"
      fullWidth
      sx={{ zIndex: 1100 }}
    >
      {!masterEntityId ? (
        <Stack sx={{ alignItems: 'center' }}>
          <CircularProgress size={30} />
        </Stack>
      ) : (
        <UpdateCompanyInformation
          masterEntityId={masterEntityId}
          isNaturalPersonRut={isNaturalPersonRut}
          editedValues={editedValues}
          setEditedValues={setEditedValues}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          refetch={refetch}
          data={data}
        />
      )}
    </FingoDialog>
  );
};

UpdateCompanyInformationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  masterEntityId: PropTypes.string.isRequired,
  isNaturalPersonRut: PropTypes.bool.isRequired,
};

export default UpdateCompanyInformationDialog;
